<template>
<div id="editForm">
    <vs-sidebar parent="editForm" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>{{title}}</h4>
                </div>
                <feather-icon icon="XIcon" @click.stop="closeWindow" class="cursor-pointer"></feather-icon>
            </div>
            <vs-divider class="mb-0" />
            <div class="vx-col w-full mb-base">
                <vx-card :title="currentReg.displayName" :subtitle="currentReg.emailAddress+ '/' + currentReg.mobileNumber" noShadow>
                    <template slot="actions">
                        <!-- <vs-button v-if="currentReg.status === 'Active'" color="danger" class="mr-2" @click.prevent="deactivateCar">Deactivate</vs-button>
                        <vs-button v-if="currentReg.status === 'Pending'" color="danger" class="mr-2" @click.prevent="deactivateCar">Deactivate</vs-button>
                        <vs-button v-if="currentReg.status === 'Presale'" color="danger" class="mr-2" @click.prevent="deactivateCar">Deactivate</vs-button> -->
                        <vs-button v-if="currentReg.status === 'Active'" color="danger" class="mr-2" @click.prevent="popupActive = true" @click="HidePreviewFileActive">Deactivate</vs-button>
                        <vs-button v-if="currentReg.status === 'Pending'" color="danger" class="mr-2" @click.prevent="popupActive = true" @click="HidePreviewFileActive">Deactivate</vs-button>
                        <vs-button v-if="currentReg.status === 'Presale'" color="danger" class="mr-2" @click.prevent="popupActive = true" @click="HidePreviewFileActive">Deactivate</vs-button>
                        <vs-button v-if="currentReg.status === 'Active'" color="success" disabled>{{currentReg.status}}</vs-button>
                        <vs-button v-else-if="currentReg.status === 'Processing'" color="warning" disabled>{{currentReg.status}}</vs-button>
                        <vs-button v-else color="danger" disabled>{{currentReg.status}}</vs-button>
                        <vs-button v-if="currentReg.status !== 'Active'" class="ml-3" color="success" @click.prevent="approveCar">Approve</vs-button>
                    </template>
                    <vs-tabs position="top" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
                        <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? 'Season Pass' : ''" @click="HidePreviewFileActive">
                            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                                <h5 class="mt-4 text-secondary">Season Pass</h5>
                                <vs-divider />
                                <div class="flex justify-between mb-2">
                                    <span>Season Pass</span>
                                    <span class="font-semibold"> {{currentReg.productName}}</span>
                                </div>
                                <div class="flex justify-between mb-2">
                                    <span>Pass Number</span>
                                    <span class="font-semibold"> {{currentReg.passNumber}}</span>
                                </div>
                                <div class="flex justify-between mb-2">
                                    <span>Pass Expires</span>
                                    <span class="font-semibold"> {{currentReg.passExpiryDate | formatLongDate }}</span>
                                </div>
                                <div class="flex justify-between mb-2">
                                    <span>Amount Paid</span>
                                    <span class="font-semibold"> {{currentReg.amountPaid | formatCurrency }}</span>
                                </div>
                                <div class="flex justify-between mb-2">
                                    <span>Payment Card</span>
                                    <span>XXXX XXXX XXXX {{currentReg.transactionCard }}</span>
                                </div>
                                <div class="flex justify-between mb-2">
                                    <span>Payment Status</span>
                                    <span class="text-success">{{currentReg.paymentStatus }}</span>
                                </div>
                            </div>
                        </vs-tab>

                        <vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? 'Vehicle Registration' : ''" @click="HidePreviewFileActive">
                            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                                <component :is="scrollbarTag" ref="mainSidebarPs" class="document-scroll-area p-0 mb-10" :settings="settings" :key="$vs.rtl">
                                    <h5 class="mt-4 text-secondary">Vehicle Registration</h5>
                                    <vs-divider />
                                    <div class="flex justify-between mb-2">
                                        <span>Registration Plate</span>
                                        <span class="font-semibold"> {{currentReg.registrationPlate}} ({{currentReg.registrationState}})</span>
                                    </div>
                                    <div class="flex justify-between mb-2">
                                        <span>Registration Expires</span>
                                        <span class="text-danger">{{currentReg.registrationExpiryDate | formatLongDate}}</span>
                                    </div>
                                </component>
                            </div>
                        </vs-tab>

                        <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? 'Driver`s Licence' : ''" @click="HidePreviewFileActive">
                            <component :is="scrollbarTag" ref="mainSidebarPs" class="document-scroll-area p-0 mb-10" :settings="settings" :key="$vs.rtl">
                                <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                                    <h5 class="mt-4 text-secondary">Driver`s Licence</h5>
                                    <vs-divider />
                                    <div class="flex justify-between mb-2">
                                        <span>Licence Number</span>
                                        <span class="font-semibold"> {{licenceData.documentNumber}}</span>
                                    </div>
                                    <div class="flex justify-between mb-2">
                                        <span>State</span>
                                        <span class="font-semibold"> {{licenceData.state}}</span>
                                    </div>
                                    <div class="flex justify-between mb-2">
                                        <span>Expiry Date</span>
                                        <span class="text-success">{{licenceData.expiryDate | formatLongDate}}</span>
                                    </div>
                                    <vs-divider />
                                    <div>
                                        <h5 class="mt-4 text-secondary">Document</h5>
                                        <vs-divider />
                                        <vs-table stripe :data="licenceDataList">
                                            <template :slot-scope="{ data }">
                                                <vs-tr :key="i" v-for="(tr, i) in licenceDataList" :data="tr">
                                                    <vs-td :class="{ 'line-through': tr.isDeleted }">
                                                        <span @click="handleViewSidebar(tr.id)">
                                                            {{ tr.originalFileName }}
                                                        </span>
                                                    </vs-td>
                                                </vs-tr>
                                            </template>
                                        </vs-table>
                                        <!-- <vx-pdf v-if="licenceData.fileExtension === '.pdf'" :key="licenceData.downloadUrl" :src="licenceData.downloadUrl" />
                                        <vx-image :key="licenceData.downloadUrl" v-else-if="licenceData.fileExtension !== '.pdf'" :src="licenceData.downloadUrl" />
                                        <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData> -->
                                    </div>
                                    <vs-divider />
                                </div>
                            </component>
                        </vs-tab>

                        <vs-tab icon-pack="feather" icon="icon-message-square" :label="!isSmallerScreen ? 'Comments' : ''" @click="HidePreviewFileActive">
                            <component :is="scrollbarTag" ref="mainSidebarPs" class="document-scroll-area p-0 mb-10" :settings="settings" :key="$vs.rtl">
                                <Comments :registrationId="this.currentReg.id" :azureUserId="this.currentReg.userId" type="registration" />
                            </component>
                        </vs-tab>

                    </vs-tabs>
                </vx-card>
            </div>
        </div>
    </vs-sidebar>

    <vs-popup class="inner-popup" classContent="popup-example" title="Deactivate Comment" :active.sync="popupActive">
        <div class="vx-row mb-base">
            <div class="vx-col w-full">
                <vs-input class="w-full" v-model="comment" placeholder="Enter your comment" />
            </div>
        </div>
        <div class="flex">
            <vs-button class="ml-2" @click="deactivateCar" color="danger">Save</vs-button>
        </div>
    </vs-popup>
    <vx-previewfile v-if="previewFileActive" :openlocationwindow="previewFileActive" :previewFileData="previewFileData" @closeWindow="closeWindow" />
</div>
</template>

<script>
import {
    profileData
} from '../../store/api/profile';
import {
    customMessages
} from './../../filters/validationmessage';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {
    carData
} from '../../store/api/car';
import {
    FileConfig
} from "../../store/fileConfig.js";
import Comments from "../../components/ShareComponents/Comments.vue";

export default {
    props: {
        openAddWindow: {
            type: Boolean,
            required: true,
            default: false
        },
        selectedReg: {
            type: Object,
            default: () => {},
        },
        regType: {
            type: String,
            default: () => "Registration"
        }
    },
    data() {
        return {
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .60
            },
            currentReg: {
                ...this.selectedReg
            },
            active: {
                ...this.openAddWindow
            },
            errorMessage: "",
            title: this.regType,
            customMessages,
            licenceData: {},
            licenceDataList: [],
            imageExt: FileConfig.ImageExt,
            //imageExt: [".jpg", ".jpeg", ".png", ".gif"],
            noData: {
                title: "No Document Found",
            },
            startDateTimeConfig: {
                wrap: true,
                altFormat: 'd-m-yy',
                altInput: true,
                allowInput: true,
                dateFormat: 'yy-m-d',
                enableTime: false,
                time_24hr: false
            },
            endDateTimeConfig: {
                wrap: true,
                altFormat: 'd-m-yy',
                altInput: true,
                allowInput: true,
                dateFormat: 'yy-m-d',
                enableTime: false,
                time_24hr: false
            },
            previewFileData: undefined,
            previewFileActive: false,
            popupActive: false,
            comment: "",
        }
    },
    components: {
        flatPickr,
        VuePerfectScrollbar,
        Comments
    },
    computed: {
        scrollbarTag() {
            return this.$store.getters.scrollbarTag
        },
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768;
        },
    },

    async created() {
        await this.loadDrivingLicence();
    },
    watch: {
        openAddWindow: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
        selectedReg: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.currentReg = newValue;
            }
        }
    },
    methods: {
        async HidePreviewFileActive() {
            this.previewFileActive = false;
        },
        async loadDrivingLicence() {
            this.licenceData = await profileData.SearchIdentityDocumentByUser(this.currentReg.userId);
            if (!this.licenceData) {
                this.licenceDataList = [];
            } else {
                this.licenceDataList = this.licenceData.files;
            }
            this.previewFileActive = false;
        },
        async approveCar() {
            this.previewFileActive = false;
            let apiResult = await carData.approveCarRegistration(this.currentReg.id);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.$emit("onClose");
        },
        async deactivateCar() {
            //this.previewFileActive= false;
            if (!confirm("are you sure ? ")) {
                return;
            }

            var data = data = {
                id: this.currentReg.id,
                commentText: this.comment
            }

            let apiResult = await carData.deactivateCarRegistration(data);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.popupActive = false;
            this.$emit("onClose");
        },
        closeWindow() {
            this.popupActive = false;
            this.previewFileActive = false;
            this.$emit("onClose");
        },
        async handleViewSidebar(id) {
            let file = this.licenceDataList.find(x => x.id == id);
            this.previewFileData = {
                fileURL: file.downloadUrl,
                fileFormat: file.fileExtension
            }
            this.previewFileActive = true;
        }
    }
}
</script>

<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        max-height: 100vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);

        @media screen and (max-width: 1200px) {
            min-width: 100vw;
            margin: 0px;
            max-height: 100vh;
        }

    }
}

.document-scroll-area {
    height: 75vh;
}
</style>
